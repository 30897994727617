import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    { path: '/', component: () => import('@/components/Login.vue') },
    { path: '/company_info', component: () => import('@/components/CompanyInfo.vue') },
    { path: '/client_management', component: () => import('@/components/ClientManagment.vue') },
    { path: '/user', component: () => import('@/components/InternalUser.vue') },
    { path: '/billalable_items', component: () => import('@/components/BillableItems.vue') },
    { path: '/pricing', component: () => import('@/components/Pricing.vue') },
    { path: '/project_card', component: () => import('@/components/ProjectCard.vue') },
    { path: '/billing', component: () => import('@/components/Billing.vue') },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
